<template>
  <div id="goods-container">
    <Head />
    <div class="main-img">
      <van-image fit="cover" width="100%" :src="imgUrl + data.ads_picture" />
      <p class="title">{{ data.name }}</p>
      <div class="sku">
        <div class="sku-item" v-for="(s, sk) in data.sku_map" :key="sk">
          <div class="sku-div" v-for="(v, vk) in s.value" :key="vk">
            <van-image fit="cover" width="100%" :src="imgUrl + v.path" />
            <p class="sku-name">{{ v.value_name }}</p>
          </div>
        </div>
      </div>
      <div class="info" v-html="data.description"></div>
      <div class="contact">contact us: <span><a href="mailto:karstudio@163.com" class="mail">karstudio@163.com</a></span></div>
      <div class="detail">
        <van-image
          v-for="(item, key) in data.carousel_picture"
          :key="key"
          fit="cover"
          width="33.33%"
          height="10rem"
          :src="imgUrl + item.image_path"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head";
export default {
  data() {
    return {
      imgUrl: "http://img.kar-studio.com",
      goods_no: this.$route.query.goods_no,
      data: {},
    };
  },
  components: {
    Head,
  },
  computed: {},
  methods: {
    getGoodsDetail() {
      this.$api.goodsDetail({ goods_no: this.goods_no }).then((res) => {
        if (res.data.status) {
          this.data = res.data.data;
        }
      });
    },
  },
  created() {
    this.getGoodsDetail();
  },
  mounted() {},
};
</script>

<style lang="scss">
#goods-container {
  .info {
    font-size: 24px;
    line-height: 34px;
    padding: 0 20px;
  }
}
</style>
<style lang="scss" scoped>
#goods-container {
  .mail {
    display: block;
    font-weight: bold;
    color: #000;
  }
  .title {
    margin: 20px 0;
    font-weight: bold;
  }
  .contact {
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
    margin: 20px 0;
    span {
      text-decoration: underline;
    }
  }
  .detail {
    overflow: hidden;
    .van-image {
      float: left;
      display: block !important;
      margin: 0 !important;
    }
  }
  .sku {
    width: 100%;
    margin: 40px 0;
    .sku-item {
      width: 100%;
      overflow: hidden;
      .sku-title {
        width: 100%;
        font-size: 26px;
        margin-bottom: 10px;
      }
      .sku-div {
        width: 20%;
        margin-right: 1%;
        text-align: center;
        display: inline-block;
        img {
          width: 100% !important;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
